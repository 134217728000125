import React from 'react';
import {Linking, Platform} from 'react-native';

import {transactionsService} from '../services';

const useDownloadPopReceipt = (
  hiddenPopUp,
  startFetch,
  endFetch,
  id,
  headers,
) => {
  const handleDownload = async () => {
    if (!id) {
      return;
    }

    try {
      hiddenPopUp();
      startFetch();

      const { file_url } = await transactionsService.getPopFileTransaction(
        headers,
        id,
      );

      if (!file_url) {
        return;
      }

      const today = new Date();
      const formattedDate = `${(today.getMonth() + 1)
        .toString()
        .padStart(2, '0')}${today
        .getDate()
        .toString()
        .padStart(2, '0')}${today.getFullYear()}`;

      if (Platform.OS === 'web') {
        const link = document.createElement('a');
        link.href = file_url;

        const filename = `receipt_${formattedDate}.pdf`;

        link.download = filename;
        link.target = '_blank';

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        Linking.openURL(file_url);
      }

      endFetch();
    } catch (error) {
      endFetch(error);
    }
  };

  return {handleDownload};
};

export default useDownloadPopReceipt;
