const es = {
  auth: {
    signInButton: 'Registrarme',
    signInButtonRedirect: 'Registrarme',
    logInButton: 'Inicia sesión',
    emailPlaceholder: 'Correo electrónico',
    passwordPlaceholder: 'Contraseña',
    passwordConfirmationPlaceholder: 'Repetir contraseña',
    minimumCharacters: '8 caracteres min.',
    great: 'Genial!',
    acceptRegister: 'Al registrarse acepta los',
    acceptRegister1: 'términos de servicio',
    acceptRegister2: ' y la ',
    acceptRegister3: 'política de privacidad',
    signinGoogle: 'Registrarse con Google',
    loginGoogle: 'Inicia sesión con Google',
    forgottenPassword: 'He olvidado mi contraseña',
  },
  validations: {
    emptyField: 'Campo vacío',
    nameRequired: 'El nombre es requerido',
    emailEmpty: 'Email vacío',
    emailInvalid: 'El formato no es válido.',
    emailRequired: 'El email es requerido',
    passwordEmpty: 'Contraseña vacía',
    passwordInvalid: 'Contraseña inválida',
    passwordRequired: 'La contraseña es requerida',
    passwordMinLength: '8 caracteres min.',
    passwordConfirm: 'Las contraseñas no coinciden.',
    addressRequired: 'la dirección es requerida',
    professionRequired: 'la profesión es requerida',
  },
  cardRequest: {
    professionPlaceholder: 'Profesión',
    addressPlaceholder: 'Dirección',
    title: 'Estás a un paso de obtener tu Vita Card',
    message: 'Completa los siguientes campos para terminar tu solicitud:',
    message1:
      'A- "Tarjeta emitida por Institución Financiera Cooperativa Coopeuch, entidad fiscalizada por la CMF y miembors de Mastercard"',
    message2:
      'B- "Infórmese sobre la garantía estatal de los depósitos en su cooperativa o en www.cmfchile.cl"',
    message3:
      'C- "Infórmese sobre las entidades autorizadas para emitir Tarjetas de Pago en el país, quienes se encuentran inscritas en los Registros de Emisores de Tarjetas que lleva la CMF, en www.cmfchile.cl"',
    button: 'Solicitar Vita Card',
  },
  screens: {
    PaymentNotAuthorized: {
      title: '¡Enlace de cobro inválido!',
      message: ' no está autorizado para recibir pagos',
    },
    successfulExchange: {
      title: '¡Intercambio exitoso!',
      button: 'Ver mis saldos',
      message: 'En unos segundos los fondos estarán disponibles en tu cuenta.',
    },
    error: {
      button: 'Reintentar',
      title: '¡Ocurrió algo!',
      message:
        'Probablemente un problema con tu conexión a internet, verifica que todo este bien',
    },
    successfulRegister: {
      button: 'Abrir correo',
      title: '¡Registro exitoso!',
      message:
        'Te hemos enviado un email para confirmar tu correo electrónico. Revisa tu bandeja de entrada o búscalo en la casilla de promociones o spam.',
    },
    successfulRequestPassword: {
      button: 'Abrir correo',
      title: '¡Tienes un nuevo correo!',
      message:
        'Revisa tu bandeja de entrada y sigue los pasos para recuperar tu contraseña',
    },
    successfulRecoverPassword: {
      button: 'Iniciar sesión',
      title: '¡Cambio exitoso!',
      message:
        'Ya puedes volver a ingresar a Vita Wallet y disfrutar de todos los beneficios.',
    },
    SuccessfulVerificationSent: {
      button: 'Entendido',
      title: '¡Información Recibida!',
      title_update: '¡Información Recibida!',
      message:
        'Estaremos revisando la información suministrada y te notificaremos vía email cuando el proceso haya concluido.',
      message2:
        // 'Gracias por completar su proceso de verificación, ya puede disfrutar de todos los beneficios.',
        'Hemos recibido correctamente tu información.',
      message_update:
        ' Tu cuenta estará lista para enviar dinero una vez finalice la revisión y actualización en un máximo de 1 día hábil.',
    },
    SuccessfulPin: {
      button: 'Iniciar sesión',
      title: '¡Ya tienes PIN!',
      message:
        'Ya puedes ingresar a Vita Wallet y disfrutar de todos los beneficios.',
    },
    SuccessfulPinRequest: {
      button: 'Abrir correo',
      title: '¡Tienes un nuevo correo!',
      message:
        'Revisa tu bandeja de entrada y sigue los pasos para recuperar tu PIN de seguridad.',
    },
    SuccessfulPinRecover: {
      button: 'Iniciar sesión',
      title: 'Cambio exitoso!',
      message:
        'Ya puedes volver a ingresar a Vita Wallet y disfrutar de todos los beneficios.',
    },
    successfulRecharge: {
      button: 'Invitar amigos',
      title: '¡Recarga exitosa!',
      message:
        'Tu transacción se verá reflejada en tu cuenta Vita Wallet en un par de minutos.',
    },
    SuccessfulBankAccountAffiliate: {
      button: 'Invitar amigos',
      title: '¡Afiliación exitosa!',
      message:
        'Ya puedes realizar retiros, recueda que recibirás el monto elegido en moneda local.',
    },
    SuccessfulAddFavorite: {
      button: 'Agregar otro',
      title: '¡Nuevo favorito!',
      message:
        'Ahora cada vez que quieras enviar dinero simplemente deberás seleccionar tu contacto favorito.',
    },
    SuccessfulUpdateFavorite: {
      button: 'Enviar dinero',
      title: '¡Favorito modificado!',
      message: 'Los cambios ingresados han sido actualizados exitosamente.',
    },
    SuccessfulDeleteFavorite: {
      button: 'Agregar nuevo favorito',
      title: '¡Favorito eliminado!',
      message:
        'El contacto ha sido eliminado de tu lista de favoritos exitosamente.',
    },
    SuccessfulWithdrawal: {
      button: 'Ver tracking de operación',
      title: '¡Retiro exitoso!',
      message: 'Se prevee que se hará efectiva esta operación el ',
    },
    SuccessfulTransfer: {
      button: 'Ver tracking de operación',
      title: '¡Envío exitoso!',
      message:
        'Transacción completada, a Elba Leal, se le hará efectiva esta operación el ',
    },
    SuccessfulSent: {
      button: 'Ver tracking de operación',
      title: '¡Envío exitoso!',
      message: 'Transacción en proceso.',
    },
    InfoMaintenanceServer: {
      title: 'Servicio en mantenimiento',
      message_1: 'Estamos fuera de servicio para mejorar tu experiencia, volveremos en breve ¡Gracias por tu comprensión!',
    },
  },
  notifications: {
    SessionClose: {
      message1: 'Por inactividad, tu sesión será cerrada en ',
      message2: ', de este modo evitaremos cualquier vulnerabilidad de seguridad.',
      button: 'Reactivar sesión',
    },
  },
  receive: {
    form: {
      moneyReceive: 'Recibir dinero',
      amountReceive: 'Monto a recibir:',
      qr: 'Ver mi dirección',
      sendLink: 'Enviar enlace',
      generateLink: 'Generar enlace',
      minMessage: 'Monto mínimo a recibir ',
    },
  },
  sent: {
    title: 'Enviar',
    options: {
      sent: 'Vita usuarios',
      transfer: 'Transferencias',
      service: 'Servicios',
    },
    form: {
      amount: 'Monto:',
      question: '¿Qué deseas enviar?',
      send_to: 'Enviar a:',
      description: 'Descripción:',
    },
  },
  exchange: {
    title: 'Crypto',
    options: {
      sent: 'Enviar',
      exchange: 'Intercambiar',
    },
    form: {
      question: '¿Qué deseas intercambiar?',
      have: 'Tengo:',
      want: 'Quiero:',
      commission: 'Comisiones incluidas en la tasa de cambio',
      button: 'Continuar',
    },
    confirmation: {
      resume: 'Resumen de la transacción',
      have: 'Tengo:',
      want: 'Quiero:',
      commission: 'Comisión:',
      button: 'Confirmar intercambio',
    },
  },
  recharge: {
    form: {
      title: 'Recargar',
      amount: 'Cuánto deseas recargar:',
      commission: 'Total comisión',
      operation: 'Total de la operación',
      button: 'Recargar',
      commissionMessage0: 'La comisión es de ',
      commissionMessage1: ' por',
      commissionMessage2: 'gastos administrativos',
    },
    bankInformation: {
      description1: 'Realiza una transferencia con el nro de cuenta',
      description2: 'mostrado a continuación a través de una cuenta',
      description3: 'bancaria a tu nombre',
      title: 'Transferencia bancaria:',
      bank: 'Banco',
      accountType: 'Tipo de cuenta',
      bankOwner: 'Titular',
      accountNumber: 'Número de cuenta',
    },
  },
  userInfo: {
    hi: 'Hola, ',
  },
  rechargeSelect: {
    balance: 'Saldo',
    affiliateTitle: 'Afiliar nueva tarjeta',
    affiliateSubtitle: 'Sólo crédito',
    webpaySubTitle: 'Otros medios de recarga',
  },
  currencies: {
    clp: 'Pesos chilenos',
    usd: 'Dólares americanos',
    btc: 'Bitcoins',
  },
  popsUp: {
    accountValidate: {
      title: 'Verificación de usuario',
      title_update: 'Actualización de perfil',
      title_incomplete: 'Completa información de perfil',
      title_rejected: 'Verificación rechazada',
      title_ok: 'Tu verificación está en progreso',
      message:
        '¡Hey! Debes verificar tu cuenta para poder continuar con el proceso.',
      message_incomplete:
        'Por favor actualiza tus datos de perfil para continuar disfrutando de tu servicio Vita Wallet.',
      message_update:
        'Mantener tu cuenta actualizada te ayudará a reforzar la seguridad de tu cuenta y a disfrutar de todos nuestros beneficios.',
      message_rejected:
        'Escribe a ayuda@vitawallet.io para conocer mas información',
      message_ok:
        'Este proceso puede demorar unos minutos, te mantendremos al tanto por correo electrónico.',
      button: 'Verificar cuenta',
      button_verification_required: 'Actualizar datos',
      button_incomplete: 'Completar datos',
      button_rejected: 'Entendido',
      button_ok: 'Entendido',
      backButton: 'Cancelar',
      verifity: 'Verificarme',
    },
    exceededLimit: {
      title: 'Superaste el límite de recarga o envío',
      message:
        'Por temas de seguridad hemos fijado límites de recarga y envíos para todos los usuarios. Solicita un desbloqueo.',
      button: 'Contacto a soporte',
      backButton: 'Cancelar',
    },
    invalidPassword: {
      title: '¡Ups! Tus datos no son correctos',
      title_invalid: '¡Superaste el número de intentos!',
      message: 'Intenta recordar tu usuario o contraseña antes de ingresar. Tienes :failed_attempts intentos más para evitar que tu cuenta sea bloqueada temporalmente.',
      message_invalid: 'Revisa tu correo electrónico.',
      button: 'Reintentar',
      button_invalid: 'Abrir correo',
    },
    confirmedEmail: {
      title: 'Falta confirmar tu correo',
      message: 'Para ingresar a tu cuenta es necesario verificar tu correo electrónico. Hemos enviado un enlace a tu email para que finalices el proceso.',
      button: 'Abrir correo',
      backButton: 'Cancelar',
    },
    updateProfile:{
      title: 'Actualización en proceso',
      message: 'Recientemente actualizaste tu documento de identidad. Nuestro equipo se encuentra validando tu información para finalizar el proceso de actualización de tu cuenta. Este proceso puede tomar algunas horas.',
      button: 'Entendido',
      backButton: 'Cancelar',
    }
  },
  common: {
    closed: 'Cerrar',
    cancel: 'Cancelar',
  },
  dashboard: {
    sign: {
      verify_account: {
        title: '¿Ya te podemos decir Vita usuario(a)? 👋🏼',
        message: 'Como primer paso puedes realizar la verificación de la cuenta para activar todas las funciones y garantizar la seguridad de todos los usuarios.\n\nTambién puedes explorar las opciones 😊.',
        buttonMessage: 'Verificar cuenta',
      },
    },
  },
};

export default es;
